import React from "react";
import { Button, Result, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
const { Text, Link } = Typography;

const FinalStep = (props) => {
  return (
    <>
      <Result
        status="success"
        title="Successfully Initiated!"
        subTitle="Reports will be available once streams are completed. 30 min est."
        // extra={[
        //   <Button type="primary" key="console">
        //     See All Cases
        //   </Button>,
        // ]}
      />
    </>
  );
};

export default FinalStep;
