import React, { useState } from "react";
// import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { Layout, Menu, Row, Col } from "antd";
import ReportPDF from "./components/FinalReport";
import AllCases from "./components/AllCases";
import Steps from "./components/Steps";
import { PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const { Header, Sider, Content } = Layout;
function App() {
  const [View, UpdateView] = useState(0);
  const [Report, ShowReport] = useState(0);

  return (
    <>
      <div style={{ width: "80%", marginLeft: "10%", marginTop: "30px" }}>
        <Button
          type="primary"
          shape="round"
          icon={View ? <ArrowLeftOutlined /> : <PlusOutlined />}
          size={15}
          onClick={() => {
            UpdateView(!View);
          }}
        >
          {View ? "Back" : "Create Case"}
        </Button>
        <br></br>
        <br></br>
        {Report ? (
          <>
            {" "}
            <ReportPDF click={ShowReport} />
          </>
        ) : (
          <></>
        )}
        {View == 0 ? (
          <>
            <Row>
              <Col span={24}>
                <AllCases click={ShowReport} />
              </Col>
            </Row>{" "}
          </>
        ) : (
          <>
            {" "}
            <Row>
              <Col span={24}>
                <Steps click={UpdateView} />
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
}

export default App;
