import React, { useState, useEffect } from "react";

import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  TimePicker,
  Switch,
  Mentions,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
const CreateCase = (props) => {
  const [CaseData, UpdateCaseData] = useState(props.data);
  const [CaseId, UpdateCaseId] = useState(
    Math.random().toString(36).substring(3)
  );

  // const onFormLayoutChange = ({ size }) => {
  //   setComponentSize(size);
  // };

  const { Option } = Mentions;

  function onChange(value) {
    console.log(value);
    console.log("Change:", value);
  }

  function onSelect(option) {
    console.log("select", option);
  }

  function UpdateData(key, value, type) {
    let NewData = CaseData;
    console.log("<<<", CaseData);
    if (type === "date") {
      // newData["CaseId"] = CaseId;
      NewData["StartTime"] = value[0];
      NewData["EndTime"] = value[1];
    }
    NewData["CaseId"] = CaseId;
    NewData[key] = value;
    console.log("???", NewData);
    props.update(NewData);
  }
  // useEffect(() => {
  //   if (props.save) {
  //     console.log("Save Case to LocalStoerage");
  //   }
  // }, [props.save]);

  return (
    <>
      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        // initialValues={{
        //   size: componentSize,
        // }}
        // onValuesChange={onChange}
        // size={componentSize}
      >
        <Form.Item label="Case Id">
          <strong>{CaseId}</strong>
        </Form.Item>
        <Form.Item label="Name">
          <Input onChange={(e) => UpdateData("name", e.target.value)} />
        </Form.Item>
        <Form.Item label="Label">
          <Input onChange={(e) => UpdateData("label", e.target.value)} />
        </Form.Item>
        <Form.Item label="Members">
          <Mentions
            style={{ width: "100%" }}
            onChange={onChange}
            onSelect={onSelect}
            defaultValue="@John"
          >
            <Option value="David">David</Option>
            <Option value="Ali">Ali</Option>
            <Option value="Tom">Tom</Option>
            <Option value="John">John</Option>
          </Mentions>
        </Form.Item>
        <Form.Item label="Type">
          <Select onSelect={(e) => UpdateData("type", e)}>
            <Select.Option value="SSV">SSV</Select.Option>
            <Select.Option value="Benchmark">Benchmark</Select.Option>
            <Select.Option value="KPI">KPI</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Priority">
          <Select onSelect={(e) => UpdateData("priority", e)}>
            <Select.Option value="High">High</Select.Option>
            <Select.Option value="Medium">Medium</Select.Option>
            <Select.Option value="Low">Low</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Time">
          {/* <TimePicker /> */}
          <RangePicker
            showTime
            onChange={(date, dateString) =>
              UpdateData("dates", dateString, "date")
            }
          />
        </Form.Item>

        <Form.Item>
          {/* <Button
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size={15}
            onClick={() => {
              localStorage.setItem("case_id", "Smith");
            }}
          >
            Create
          </Button> */}
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateCase;
