import React, { useState, useEffect } from "react";
import { Select, Checkbox, Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
const { Option } = Select;
const plainOptions = ["SSV", "Benchmarking", "UPI"];

const CreateReport = () => {
  return (
    <>
      <br></br>
      <br></br>
      <Select
        showSearch
        style={{ width: 300 }}
        placeholder="Select Template"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option value="jack">Weekly</Option>
        <Option value="lucy">Monthly</Option>
        <Option value="tom">Quarterly</Option>
      </Select>
      <br></br>
      <br></br>
      {/* <Divider orientation="left" plain></Divider> */}

      {/* <Checkbox.Group options={plainOptions} defaultValue={["Apple"]} />
      <br></br>
      <br></br> */}
      {/* <Divider plain></Divider> */}
    </>
  );
};

export default CreateReport;
