import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Row, Col, Input, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Steps, Button, message } from "antd";
import CreateCase from "./CreateCase";
import CreateStreams from "./CreateStreams";
import CreateReport from "./CreateReport";
import Final from "./FinalSetp";

const { Step } = Steps;
const steps = [
  {
    title: "Create Case",
    content: "First-content",
  },
  {
    title: "Add Streams",
    content: "Second-content",
  },
  {
    title: "Publish Reports",
    content: "Last-content",
  },
  {
    title: "Done",
    content: "Last-content",
  },
];

const AddStream = (props) => {
  const [current, setCurrent] = React.useState(0);
  const [StepSave, UpdateStepSave] = useState({
    Case: 0,
    Stream: 0,
    Report: 0,
  });
  const [CaseData, UpdateCaseData] = useState({
    Case: {
      name: "",
      label: "",
      members: [],
      type: "",
      priority: "",
      startTime: "",
      endTime: "",
    },
    Streams: [],
    Report: {},
  });
  const [CasesData, UpdateCasesData] = useState({});

  const next = () => {
    if (current === 0) {
      StepSave.Case = 1;

      let AllCases = [];
      let OldCases = localStorage.getItem("Cases");
      if (OldCases === null) {
        AllCases.push(CasesData);
        localStorage.setItem("Cases", JSON.stringify(AllCases));
      } else {
        OldCases = JSON.parse(OldCases);
        OldCases.push(CasesData);
        localStorage.setItem("Cases", JSON.stringify(OldCases));
      }
      UpdateStepSave(StepSave);
    } else if (current === 1) {
      StepSave.Stream = 1;
      UpdateStepSave(StepSave);
    } else if (current === 2) {
      StepSave.Report = 1;
      UpdateStepSave(StepSave);
    }
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    console.log(CasesData);
  }, [CasesData]);
  return (
    <>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">
        {" "}
        {steps[current].title === "Create Case" ? (
          <>
            {CasesData.priority}
            <CreateCase
              save={StepSave.Case}
              data={CasesData}
              update={UpdateCasesData}
            />
          </>
        ) : (
          <></>
        )}
        {steps[current].title === "Add Streams" ? (
          <>
            <CreateStreams />
          </>
        ) : (
          <></>
        )}
        {steps[current].title === "Publish Reports" ? (
          <>
            <CreateReport />
          </>
        ) : (
          <></>
        )}
        {steps[current].title === "Done" ? (
          <>
            <Final />
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="steps-action">
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}
          >
            Done
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </div>
    </>
  );
};

export default AddStream;
