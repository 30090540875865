import React, { useState, useEffect } from "react";
import { Row, Col, Tag, Typography, Divider } from "antd";
import {
  SettingOutlined,
  DotChartOutlined,
  LoadingOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { Card, Table, Button, Nav } from "react-bootstrap";
import Faker from "faker";
import { OmitProps } from "antd/lib/transfer/ListBody";
const { Title } = Typography;

const AddStream = (props) => {
  var Operators = ["Telenor", "Zong", "Ufone", "Jazz"];
  var Reports = ["SSV", "Benchmark", "Cluster KPIs", "Customer Complaint"];
  var Status = ["Ready", "Processing", "Scheduled"];

  const [Records, UpdateRecords] = useState([]);
  useEffect(() => {
    // let records = Math.floor(Math.random() * 100);
    let Cases = localStorage.getItem("Cases");
    Cases = JSON.parse(Cases);
    console.log(Cases);
    if (Cases) {
      UpdateRecords(Cases);
    }

    let records = 27;

    let AllCases = [];
    for (let i = 0; i < records; i++) {
      AllCases.push({
        CaseId: Faker.random.number(),
        Operator: Operators[(Math.random() * Operators.length) | 0],
        Report: Reports[(Math.random() * Reports.length) | 0],
        Status: Status[(Math.random() * Status.length) | 0],
        Name: Faker.database.column(),
        Members: Faker.name.firstName(),
        Date: Faker.date.past(2).toString(),
        Title: Faker.hacker.noun(),
      });
    }

    // UpdateRecords(AllCases);
  }, []);
  return (
    <>
      <div className="site-card-wrapper">
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <Divider rientation="center">
              {" "}
              <b>
                <Title level={4}>{Records.length} Cases</Title>
              </b>
            </Divider>
            <Nav activeKey="/home">
              <Nav.Item>
                <Nav.Link
                  target="_blank"
                  href="https://analytics.revolutionapp.io/views/BenchmarkingDashboardwithcoverage/Dashboard4?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"
                >
                  Benchmark Reports
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="https://analytics.revolutionapp.io/views/SSVPortal/SSVPortal?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"
                  target="_blank"
                >
                  Customer Complaint Reports
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href="https://analytics.revolutionapp.io/views/SSVPortal/ComplaintsPortal?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link"
                  target="_blank"
                >
                  {" "}
                  SSV Reports
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="disabled" target="_blank">
                  Cluster KPI Reports
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Col>
        </Row>
        <br></br>

        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Label</th>
                <th>Start</th>
                <th>End</th>
                {/* <th>Report</th> */}
                <th>Type</th>
                <th>Priority</th>
                {/* <th>Status</th> */}
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {Records ? (
                Records.map((data) => (
                  <tr>
                    <td> {data.CaseId}</td>
                    <td>{data["label"]}</td>
                    <td>{data["StartTime"]}</td>
                    <td>{data["EndTime"]}</td>
                    {/* <td>{data["report"]}</td> */}
                    <td>{data["type"]}</td>
                    <td>{data["priority"]}</td>
                    {/* <td>{data["status"]}</td> */}
                    {/* <td></td>{" "} */}
                    {/* <Button variant="info" onClick={() => props.click(1)}>
                        View
                      </Button> */}
                    {/* <td>
                    {data.Status === "Processing" ||
                    data.Status === "Scheduled" ? (
                      <LoadingOutlined style={{ fontSize: "20px" }} />
                    ) : (
                      <>
                        {" "}
                        <CheckCircleFilled
                          style={{ fontSize: "20px", color: "green" }}
                        />
                      </>
                    )}
                  </td> */}
                    {/* <td>
                    {data.Status === "Processing" ||
                    data.Status === "Scheduled" ? (
                      <></>
                    ) : (
                      <>
                        {" "}
                       
                      </>
                    )}
                  </td> */}
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </Table>
        </>
        {/* <Card
                  title={`Case Id : ${data.CaseId}`}
                  bordered={true}
                  actions={[
                    <SettingOutlined key="setting" />,
                    // <EditOutlined key="edit" />,
                    <DotChartOutlined
                      key="ellipsis"
                      onClick={() => props.click(1)}
                    />,
                  ]}
                >
              
                  <p>Operator: {data["Operator"]}</p>
                  <p>Report Type: {data["Report"]}</p>
               
                  <p>
                    {" "}
                    Members:{" "}
                    <Tag color="blue">
                      {data["Members"]} {Faker.name.firstName()}
                    </Tag>
                    <Tag color="blue">{Faker.name.firstName()}</Tag>
                    <Tag color="blue">{Faker.name.firstName()}</Tag>
                  </p>
                  <p>
                   
                  </p>
                </Card> */}
      </div>
    </>
  );
};

export default AddStream;
