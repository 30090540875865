import React, { useState } from "react";
import { Row, Col, Input, DatePicker, Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
const { Option } = Select;

const AddStream = () => {
  const { RangePicker } = DatePicker;
  const [Count, UpdateCount] = useState(1);
  function Streams() {
    for (let i = 0; i <= Count; i++) {
      return;
    }
  }
  return (
    <>
      <br></br>
      <br></br>
      {[
        Array.apply(0, Array(Count)).map(() => (
          <>
            <Row gutter={30}>
              <Col span={4}>
                <Input placeholder="Name" />
              </Col>
              <Col span={4}>
                {" "}
                <Input placeholder="Label" />
              </Col>

              <Col span={4}>
                <RangePicker showTime />
              </Col>
              <Col span={4}>
                {" "}
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select HandSet"
                  optionFilterProp="children"
                  // onChange={onChange}
                  // onFocus={onFocus}
                  // onBlur={onBlur}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Sony-2349382403003">Sony-2349382403003</Option>
                  <Option value="Mi-A3-3248975629930">
                    Mi-A3-3248975629930
                  </Option>
                  <Option value="OnePlus-3767400028883">
                    OnePlus-3767400028883
                  </Option>
                </Select>
              </Col>
              <Col span={4}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select Script"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Sony-2349382403003">Data Upload (10MB)</Option>
                  <Option value="Mi-A3-3248975629930">
                    Data Download (10MB)
                  </Option>
                  <Option value="OnePlus-3767400028883">Voice Quality</Option>
                  <Option value="OnePlus-3767400028883">Throughput</Option>
                </Select>
              </Col>
              <Col span={4}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select Route"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Sony-2349382403003">G-9/4</Option>
                  <Option value="Mi-A3-3248975629930">Blue Area</Option>
                  <Option value="OnePlus-3767400028883">F-10 - E-7</Option>
                </Select>
              </Col>

              <Col span={4}></Col>
              <Col span={4}></Col>
            </Row>
            <br></br>
          </>
        )),
      ]}
      <p
        style={{ cursor: "pointer" }}
        onClick={() => {
          UpdateCount(Count + 1);
        }}
      >
        {" "}
        <PlusCircleOutlined style={{ fontSize: "18px" }} /> Stream
      </p>
    </>
  );
};

export default AddStream;
