import React from "react";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const FinalReport = (props) => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100vw",
          height: "100vh",
          backgroundColor: "white",
          zIndex: 2,
        }}
      >
        <Button
          type="primary"
          shape="round"
          icon={<ArrowLeftOutlined />}
          size={15}
          style={{ position: "fixed", top: "40px", left: "20px", zIndex: 3 }}
          onClick={() => {
            props.click(0);
          }}
        >
          Close
        </Button>
        <embed
          src="reports.html"
          width="100%"
          height="100%"
          style={{ marginTop: "100px", width: "100vw" }}
        ></embed>
      </div>
    </>
  );
};

export default FinalReport;
